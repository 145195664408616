import React from "react";
import { Link } from "react-router-dom";
import about from "../../assets/about.jpeg";

const About = () => {
  return (
    <>
      <div className="relative flex items-top justify-center min-h-screen bg-white dark:bg-gray-900 sm:items-center sm:pt-0">
        <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div className="overflow-auto">
            <div className="container mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                <div className="max-h-96 md:h-auto">
                  <img
                    className="w-screen h-screen object-cover object-top"
                    src={about}
                    alt=""
                  />
                </div>
                <div className="flex bg-gray-800 text-white p-10">
                  <div className="mb-auto mt-auto max-w-lg">
                    <h1 className="text-3xl uppercase">Nishan Kadel</h1>
                    <p className="font-semibold mb-5">Full Stack Developer</p>
                    <p>
                      Hi there! I am Nishan Kadel, student of Computer Science.
                      All the way from Itahari-9, Sunsari, Nepal. I am a
                      hardworking and ambitious individual with a great passion
                      for the Computing industry. I have an outstanding academic
                      record and thorough knowledge of various programming
                      Languages. I am grounded, solution-oriented and
                      target-oriented person.
                    </p>
                    <p className="font-semibold mt-5">Few Highlights</p>
                    <ul className="list-disc ml-5">
                      <li>Full Stack Web Development</li>
                      <li>Interactive Web Applications</li>
                      <li>Building REST APIs</li>
                      <li>Managing Databases</li>
                      <li>And Other....</li>
                    </ul>
                    <Link to="/hire-me">
                      <button className="transition ease-in-out delay-150 bg-green-600 hover:-translate-y-1 hover:scale-110 hover:bg-green-500 duration-300 rounded-md py-3 px-7 mt-6 text-white">
                        <i className="fa-solid fa-handshake"></i> Hire Me
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
