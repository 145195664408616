import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="flex justify-center px-4 text-gray-100 bg-gray-700 ">
        <div className="container py-6 text-center">
          Made with 🔥 by Nishan Kadel
          <hr className="h-px mt-6 bg-gray-800 border-none" />
         
        </div>
      </footer>
    </>
  );
};

export default Footer;
