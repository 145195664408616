import React from "react";
import { Link } from "react-router-dom";
import nishankadel from "../../assets/nishankadel.png";
import NishanKadelCV from "../../assets/NishanKadelCV.pdf";
import Typical from "react-typical";

const Home = () => {
  return (
    <>
      <div className="py-16 bg-gray-900">
        <div className="container m-auto px-6">
          <div className="lg:flex justify-between items-center">
            <div className="lg:w-6/12 lg:p-0 p-7">
              <h1 className="sm:text-5xl text-4xl text-white font-bold leading-tight mb-5 capitalize">
                Hi! 👋, I'm Nishan Kadel.
              </h1>
              <p className="text-xl text-zinc-200">
                Motivated
                <span className="text-xl p-1 text-green-600 font-semibold">
                  Developer
                </span>
                graduate from Itahari International College,
                <span className="text-2xl pl-2 pr-2 text-green-600 font-semibold">
                  London Met University
                </span>
                with experiences in backend as well as frontend development.
                Proven experience in developing and testing code, APIs and
                administering web applications. Seeking a full-time backend
                development position to deliver solutions to business needs.
              </p>
              <div className="py-3 mt-4 flex gap-x-4 sm:text-5xl text-4xl text-white font-bold leading-tight mb-5 capitalize">
                <Typical
                  steps={[
                    "I am Nishan Kadel...",
                    1000,
                    "Backend Developer! 😎",
                    1000,
                    "ReactJS Developer! 👨‍💻",
                    1000,
                    "Web App Developer! 💻",
                    1000,
                    "Full Stack Developer! 🤓",
                    1000,
                    "MERN Developer! 🖥",
                    1000,
                  ]}
                  loop={Infinity}
                  wrapper="p"
                />
              </div>

              <div className="py-1 flex gap-x-4">
                <Link
                  to="/hire-me"
                  className="rounded p-3 text-white transition ease-in-out delay-150 bg-green-600 hover:-translate-y-1 hover:scale-110 hover:bg-green-500 duration-300"
                >
                  <i className="fa-solid fa-handshake"></i> Hire Me
                </Link>
                <a
                  href={NishanKadelCV}
                  download="Nishan Kadel Resume"
                  className="rounded p-3 text-white transition ease-in-out delay-150 bg-indigo-600 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
                >
                  <i className="fa-solid fa-download"></i> Get CV
                </a>
              </div>

              <div className=" flex gap-x-4">
                <a
                  href="https://www.facebook.com/thenishankadel"
                  className="transition ease-in-out delay-150 text-white border mt-5 px-5 py-2 border-white rounded-lg border-t-2 hover:bg-green-600  cursor-pointer border-l-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/thenishankadel"
                  className="transition ease-in-out delay-150 text-white border mt-5 px-5 py-2 border-white rounded-lg border-t-2 hover:bg-green-600  cursor-pointer border-l-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://github.com/nishankadel"
                  className="transition ease-in-out delay-150 text-white border mt-5 px-5 py-2 border-white rounded-lg border-t-2 hover:bg-green-600  cursor-pointer border-l-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-github"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/nishan-kadel-b5470818a/"
                  className="transition ease-in-out delay-150 text-white border mt-5 px-5 py-2 border-white rounded-lg border-t-2 hover:bg-green-600  cursor-pointer border-l-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </div>
            </div>
            <div className="lg:w-4/12  scale-100 rounded-full ">
              <img
                className="rounded-full"
                alt="nishan logo"
                width="350"
                height="350"
                src={nishankadel}
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
