const projects = [
  {
    id: 1,
    projectType: "Project Management System",
    projectName: "TaskHub",
    projectDescription:
      "This web application is a project management system that allows users to create projects, add tasks to those projects, and assign those tasks to other users. It also allows users to create and manage their own tasks. This application was built using React, Redux, Node, Express, and MongoDB.",
    projectLink: "https://taskhub-client.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662203628/projects/1662203612175_q9yghn.png",
  },
  {
    id: 2,
    projectType: "E-Commerce Medicine",
    projectName: "Drug-Loft-Xpress",
    projectDescription:
      "This web application is an e-commerce medicine website that allows users to purchase medicines online. It also allows users to buy products. It has features like medicine requests, E-prescription, Appointnent and Chat. This application was built using React, Redux, Node, Express, and MongoDB.",
    projectLink: "https://dlx-client.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662203387/projects/dlxclient_wj34qn.png",
  },
  {
    id: 3,
    projectType: "E-Commerce Medicine",
    projectName: "Drug-Loft-Xpress: Admin",
    projectDescription:
      "This web application is an admin side of an e-commerce medicine website that allows admin to add, delete, update medicines and blogs online. It also manages users, feedbacks, orders , etc. This application was built using React, Redux, Node, Express, and MongoDB.",
    projectLink: "https://dlx-admin.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662203385/projects/dlxadmin_abbrvs.png",
  },
  {
    id: 4,
    projectType: "Movie Search Engine",
    projectName: "Movie-Info",
    projectDescription:
      "This web application is a movie search engine that allows users to search for movies and get information about them. This application was built using Node, Express, ejs, Movie API and MongoDB.",
    projectLink: "https://movies-details-kadel.herokuapp.com/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662203853/projects/1662203821523_fho8te.png",
  },
  {
    id: 5,
    projectType: "Image Uploader",
    projectName: "Image Upload Preview",
    projectDescription:
      "This web application is an image uploader that allows users to upload images and preview them. This application was built using Node, Express, EJS, multer and MongoDB.",
    projectLink: "https://uploadimg-kadel.herokuapp.com/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662203852/projects/1662203838474_vurhpg.png",
  },
  {
    id: 6,
    projectType: "Countdown Timer",
    projectName: "Next Birthday Counter",
    projectDescription:
      "This is a countdown timer that allows users to count down to their next birthday. This application was built using HTML, CSS, JS.",
    projectLink: "https://nishankadel.github.io/Birthday-Countdown/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662205715/projects/1662205669141_qmm2ds.png",
  },
  {
    id: 7,
    projectType: "Expense Tracker",
    projectName: "Track-Expense",
    projectDescription:
      "This is a expense tracker that allows users to track their expenses. This application was built using MERN Stack.",
    projectLink: "https://expenses-tracker-sigma.vercel.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662206813/projects/1662206802614_y9uhfw.png",
  },
  {
    id: 8,
    projectType: "Netflix UI Clone",
    projectName: "nk-flix",
    projectDescription:
      "This web application is a Netflix UI clone which netflix allows users to watch movies and series. This application was built using React and Redux",
    projectLink: "https://nk-flix.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662212659/projects/1662212079666_h4fjh2.png",
  },
  {
    id: 9,
    projectType: "Tesla UI Clone",
    projectName: "nk-tesla",
    projectDescription:
      "This web application is a Tesla UI clone which Tesla allows users to buy cars. This application was built using React and Redux",
    projectLink: "https://nk-tesla.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662212657/projects/1662212501892_kqmmer.png",
  },

  {
    id: 10,
    projectType: "Portfolio",
    projectName: "Nishan Kadel Portfolio",
    projectDescription:
      "This web application is build using React and deployed on netlify. This is the personal portfolio of Nishan Kadel.",
    projectLink: "https://nishankadel.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662515106/projects/1662515072619_cqnw23.png",
  },

  {
    id: 11,
    projectType: "Exam Management System",
    projectName: "ExamNP",
    projectDescription:
      "This web application is a exam management system that allows admin to create exams and students, add questions to those exams, and assign those exams to other students. It also allows users to create and manage their own exams. This application was built using React,  Node, Express, Tailwind css and MongoDB.",
    projectLink: "https://examnp.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662603655/projects/1662603593308_o2jrlm.png",
  },

  {
    id: 12,
    projectType: "Blog/Article Platform",
    projectName: "WriteMe",
    projectDescription:
      "This web application is a blog/article platform that allows users to write blogs and articles. This application was built using React, Node, Express, Tailwind css and MongoDB.",
    projectLink: "https://write-me.netlify.app/",
    projectImage:
      "https://res.cloudinary.com/dpng7p48z/image/upload/v1662825726/projects/1662825687585_lkuwcv.png",
  },

  //   {
  //     id: ,
  //     projectType: "",
  //     projectName: "",
  //     projectDescription:
  //       "",
  //     projectLink: "",
  //     projectImage:
  //       "",
  //   },
];

export default projects;
