import React from "react";
import "./Resume.css";

const Resume = () => {
  return (
    <>
      <div className="relative text-white bg-gray-900">
        <section className="px-20 pb-10 pt-10">
          <h4 className="text-3xl font-bold text-center md:text-left">
            My Skills
            <hr />
          </h4>
          <div className="p-6 mr-2 mt-5 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
            <h1 className="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
              Technical Skills
            </h1>

            <div className="flex flex-col mt-2 text-gray-600 dark:text-gray-400">
              <p>HTML & CSS</p>
              <div className="container">
                <div className="skills html">90%</div>
              </div>
              <p>JavaScript</p>
              <div className="container">
                <div className="skills javascript">95%</div>
              </div>
              <p>Node.JS</p>
              <div className="container">
                <div className="skills nodejs">96%</div>
              </div>
              <p>React.JS</p>
              <div className="container">
                <div className="skills reactjs">70%</div>
              </div>
              <p>Python</p>
              <div className="container">
                <div className="skills python">75%</div>
              </div>
              <p>Git & GitHub</p>
              <div className="container">
                <div className="skills git">75%</div>
              </div>
              <p>MYSQL</p>
              <div className="container">
                <div className="skills mysql">80%</div>
              </div>
              <p>MongoDB</p>
              <div className="container">
                <div className="skills mongodb">95%</div>
              </div>
              <p>Docker</p>
              <div className="container">
                <div className="skills docker">55%</div>
              </div>
              <p>GraphQL</p>
              <div className="container">
                <div className="skills graphql">30%</div>
              </div>
              <p>TypeScript</p>
              <div className="container">
                <div className="skills typescript">85%</div>
              </div>
            </div>
          </div>
          <div className="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
            <h1 className="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
              Professional Skills
            </h1>

            <div className="flex flex-col mt-2 text-gray-600 dark:text-gray-400">
              <ul className="list-decimal ml-10 text-xl under">
                <li>Web Programming</li>
                <li>Database knowledge</li>
                <li>Time management</li>
                <li>Critical thinking</li>
                <li>Problem-solving</li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- component --> */}
      <div className="relative text-white bg-gray-900">
        <hr className="border-gray-400 mx-44" />
        {/* <!-- Resume --> */}
        <section className="px-20 mt-10">
          <h4 className="mb-8 text-3xl font-bold text-center md:text-left">
            Experience
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-6 my-10">
            <div className="flex flex-col col-span-2 mb-4 md:mb-0">
              <h5 className="text-xl md:text-2xl font-bold">
                Backend Developer Intern (Node.JS)
              </h5>
              <h6 className="text-lg font-bold">
                <a
                  className="text-blue-500 underline"
                  href="https://www.linkedin.com/company/nassecio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  NASSEC PVT. LTD
                </a>
              </h6>
              <p>Jul 2021 - Oct 2021</p>
            </div>
            <div className="flex flex-col col-span-4">
              <p>
                Build APIs in Node.js for web security project, Volunteer in
                &nbsp;
                <a
                  className="text-blue-500 underline"
                  href="https://reconwithme.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Reconwithme project
                </a>
                , Build APIs for project Dealz -platform for lawyers.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-6 my-10">
            <div className="flex flex-col col-span-2 mb-4 md:mb-0">
              <h5 className="text-xl md:text-2xl font-bold">
                Backend Developer Full-Time (Node.JS)
              </h5>
              <h6 className="text-lg font-bold">
                {" "}
                <a
                  className="text-blue-500 underline"
                  href="https://www.linkedin.com/company/nassecio/"
                  target="_blank"
                  rel="noreferrer"
                >
                  NASSEC PVT. LTD
                </a>
              </h6>
              <p>Jul 2021 - Oct 2021</p>
            </div>
            <div className="flex flex-col col-span-4">
              <p>
                Contribute building APIs for &nbsp;
                <a
                  className="text-blue-500 underline"
                  href="https://nftsoul.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Project NFT Soul
                </a>
                - Used for creating NFTs collections, Handled Backend of Dealz,
                Build small programs and APIs (Smart Contracts) on Rust, Node.JS
                and Solana for token and SOL streaming.
              </p>
            </div>
          </div>
        </section>
        <hr className="border-gray-400 mx-44" />
        {/* <!-- Resume --> */}
        <section className="px-20 mt-10">
          <h4 className="text-3xl font-bold text-center md:text-left">
            Education
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-6 py-10">
            <div className="flex flex-col col-span-2 mb-4 md:mb-0">
              <h5 className="text-xl md:text-2xl  font-bold">
                BSc (Hons) COmputing
              </h5>
              <h6 className="text-lg font-bold">London Met University</h6>
              <p>Sept 2019 - Jun 2022</p>
            </div>
            <div className="flex flex-col col-span-4 ">
              <p>
                I completed my BSc (Hons) Computing degree at Itahari
                International COllege, Itahari partnered from London Met
                University, UK. I learned a lot of things from this course. I
                learned about the basics of programming, web development,
                Algorithms and data structures, software development, database
                management, and many more. I scored above 70% in my bachelor.
              </p>
            </div>
          </div>{" "}
          <div className="grid grid-cols-1 md:grid-cols-6 py-10">
            <div className="flex flex-col col-span-2 mb-4 md:mb-0">
              <h5 className="text-xl md:text-2xl  font-bold">+2 Science</h5>
              <h6 className="text-lg font-bold">Vishwa Adarsha College</h6>
              <p>Sept 2017 - Sept 2019</p>
            </div>
            <div className="flex flex-col col-span-4 ">
              <p>
                I completed my +2 Science from Vishwa Adarsha College, Itahari.
                I was the average student of my my college. I scored 2.78 GPA in
                my +2 Science.
              </p>
            </div>
          </div>{" "}
          <div className="grid grid-cols-1 md:grid-cols-6 py-10">
            <div className="flex flex-col col-span-2 mb-4 md:mb-0">
              <h5 className="text-xl md:text-2xl  font-bold">
                Secondary Level
              </h5>
              <h6 className="text-lg font-bold">
                Namuna English Secondary School
              </h6>
              <p>2004 - 2017</p>
            </div>
            <div className="flex flex-col col-span-4 ">
              <p>
                I completed my secondary level education from Namuna English
                Secondary School, Gauradaha. I was a good student and I got 3.35
                GPA in SEE examination.
              </p>
            </div>
          </div>
        </section>{" "}
        <hr className="border-gray-400 mx-44" />
        {/* <!-- Resume --> */}
        <section className="px-20 mt-10">
          <h4 className="text-3xl font-bold text-center md:text-left">
            Interests
          </h4>
          <div className="flex justify-evenly py-10">
            <button className="rounded p-3 text-white transition ease-in-out delay-150 bg-yellow-600 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 duration-300">
              Programming & Coding
            </button>
            <button className="rounded p-3 text-white transition ease-in-out delay-150 bg-yellow-600 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 duration-300">
              Watching Movies
            </button>
            <button className="rounded p-3 text-white transition ease-in-out delay-150 bg-yellow-600 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 duration-300">
              Travelling
            </button>
            <button className="rounded p-3 text-white transition ease-in-out delay-150 bg-yellow-600 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 duration-300">
              Reading Books
            </button>
            <button className="rounded p-3 text-white transition ease-in-out delay-150 bg-yellow-600 hover:-translate-y-1 hover:scale-110 hover:bg-yellow-500 duration-300">
              Cooking
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default Resume;
