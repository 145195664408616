import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import nishankadel from "../../assets/nishankadel.png";

const Navbar = () => {
  return (
    <>
      <Disclosure as="nav" className="bg-gray-700 navbar">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-around h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <i
                        className="fa-solid fa-xmark block h-6 w-6"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-bars block h-6 w-6"
                        aria-hidden="true"
                      ></i>
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex items-center justify-between sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <div className="flex">
                      <label className="block lg:hidden h-8 pt-1 w-auto text-xl text-white font-semibold mr-4">
                        Nishan Kadel
                      </label>
                      <Link
                        to="/hire-me"
                        className=" block lg:hidden h-8 pt-1 w-auto text-white border border-green-900 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white
                          px-3 py-2  text-sm font-medium"
                      >
                        Hire Me
                      </Link>
                    </div>

                    <div className="flex">
                      <img
                        className="hidden lg:block rounded-full h-8 w-auto"
                        src={nishankadel}
                        alt="DLX-Logo"
                      />
                      <label className="hidden lg:block h-8 pt-1 w-auto text-xl text-white font-semibold mr-4 ml-3">
                        Nishan Kadel
                      </label>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6 pl-20">
                    <div className="flex space-x-4">
                      <Link
                        to="/"
                        className=" text-white hover:border-b-2 hover:border-indigo-600
                          px-3 py-2 text-sm font-medium"
                      >
                        Home
                      </Link>
                      <Link
                        to="/about"
                        className=" text-white hover:border-b-2 hover:border-indigo-600
                          px-3 py-2 text-sm font-medium"
                      >
                        About
                      </Link>
                      <Link
                        to="/resume"
                        className=" text-white hover:border-b-2 hover:border-indigo-600
                          px-3 py-2 text-sm font-medium"
                      >
                        Resume
                      </Link>
                      <Link
                        to="/projects"
                        className=" text-white hover:border-b-2 hover:border-indigo-600
                          px-3 py-2 text-sm font-medium"
                      >
                        Projects
                      </Link>
                      <Link
                        to="/contact"
                        className=" text-white hover:border-b-2 hover:border-indigo-600
                          px-3 py-2 text-sm font-medium"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                  <div className="hidden sm:block sm:ml-6 pl-20">
                    <div className="flex space-x-4">
                      <Link
                        to="/hire-me"
                        className=" text-white border border-green-900 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white
                          px-3 py-2  text-sm font-medium"
                      >
                        Hire Me
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-4 pt-2 pb-3 space-y-1">
                <Link
                  to="/"
                  className=" text-white hover:bg-gray-700 hover:text-white
                          px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className=" text-white hover:bg-gray-700 hover:text-white
                          px-3 py-2 rounded-md text-sm font-medium"
                >
                  About
                </Link>
                <Link
                  to="/resume"
                  className=" text-white hover:bg-gray-700 hover:text-white
                          px-3 py-2 rounded-md text-sm font-medium"
                >
                  Resume
                </Link>
                <Link
                  to="/projects"
                  className=" text-white hover:bg-gray-700 hover:text-white
                          px-3 py-2 rounded-md text-sm font-medium"
                >
                  Projects
                </Link>
                <Link
                  to="/contact"
                  className=" text-white hover:bg-gray-700 hover:text-white
                          px-3 py-2 rounded-md text-sm font-medium"
                >
                  Contact
                </Link>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Navbar;
