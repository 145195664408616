import React from "react";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import projects from "../../utils/projects";

const Latest_Project = projects.reverse();
const Projects = () => {
  return (
    <>
      <section className="text-white bg-gray-900 pb-10">
        <div className="container px-3 py-3 mx-auto">
          <div className="flex flex-wrap w-full mb-8">
            <div className="w-full mb-6 lg:mb-0">
              <h1 className="sm:text-4xl text-5xl font-bold title-font mb-2 ">
                My Projects
              </h1>
              <hr />
            </div>
          </div>

          <div className="flex flex-wrap -m-4">
            {Latest_Project.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
