import React from "react";

const ProjectCard = ({ project }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center relative mt-5 ml-5 mb-5">
        <div
          id="partnerCard"
          className="bg-[#1c1c1c] text-white overflow-hidden rounded-md max-w-sm p-2 min-h-[500px] flex flex-col"
        >
          <div>
            <h3 className="text-left pl-8 pb-4 pt-2 text-xl">
              {project.projectType}
            </h3>
          </div>

          <div className="flex items-center justify-center bg-[#2a2a2a] min-h-[200px]">
            <a
              className="flex items-center justify-center"
              href={project.projectLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src={project.projectImage}
                alt={project.projectImage}
                className="w-auto h-auto object-cover"
              />
            </a>
          </div>
          <div className="grid grid-cols-4">
            <div className="p-4 pr-0 text-lg pt-10 col-span-3">
              <p>{project.projectName}</p>
            </div>
            <a href={project.projectLink} target="_blank" rel="noreferrer">
              <div className="col-span-1 pt-4 cursor-pointer">
                <div className="w-20 h-20 shadow-inner shadow-[#2a2a2a] mt-auto ml-auto flex flex-col items-center justify-center">
                  <p className="text-semibold text-sm">Visit Here</p>
                </div>
              </div>
            </a>
          </div>

          <div className="mt-4 pl-4">
            <p>{project.projectDescription}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
