import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Spinner from "../../Components/Spinner/Spinner";
import { toast } from "react-toastify";

const HireMe = () => {
  const form = useRef();
  
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  var templateParams = {
    from_name: fullName,
    from_email: email.trim(),
    message: message,
  };

  const handleMail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success("Message sent successfully");
          console.log(
            `Message ssent successfully to Nishan kadel from ${email}`
          );
        },
        (error) => {
          console.log(error.text);
          toast.error("Error sending message");
        }
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setFullName("");
        setEmail("");
        setMessage("");
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="flex min-h-screen text-white items-center justify-start bg-gray-900">
        <div className="mx-auto w-full max-w-lg">
          <h1 className="text-4xl font-medium">Hire Me</h1>
          <p className="mt-3">
            Email me at nishankadel39@gmail.com or message me here:
          </p>

          <form className="mt-10" ref={form} onSubmit={handleMail}>
            <div className="grid gap-6 sm:grid-cols-2">
              <div className="relative z-0">
                <input
                  type="text"
                  name="user_name"
                  className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm  focus:border-green-600 focus:outline-none focus:ring-0"
                  placeholder=" "
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-green-600 peer-focus:dark:text-green-500">
                  Your name
                </label>
              </div>
              <div className="relative z-0">
                <input
                  type="email"
                  name="user_email"
                  className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm  focus:border-green-600 focus:outline-none focus:ring-0"
                  placeholder=" "
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-green-600 peer-focus:dark:text-green-500">
                  Your email
                </label>
              </div>
              <div className="relative z-0 col-span-2">
                <textarea
                  name="message"
                  rows="5"
                  className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm focus:border-green-600 focus:outline-none focus:ring-0"
                  placeholder=" "
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-green-600 peer-focus:dark:text-green-500">
                  Your message
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="mt-5 rounded-md bg-green-600 px-10 py-2 text-white"
            >
              <i className="fa-solid fa-envelope"></i> Send Email
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default HireMe;
